<template>
  <CModal
    :show.sync="boo_modal"
    :no-close-on-backdrop="true"
    :centered="false"
    title="Modal title 2"
    :size="size"
    color="info"
  >
    <div class="row">
     
      <CCol lg="12" md="12" sm="12" xs="12">
        <CInput
          label="Cantidad"
          v-model="quantity"
          placeholder="Monto a pagar"
          v-on:keypress="isNumber($event)"
        ></CInput>
      </CCol>
    </div>
    <template #header>
      <h6 class="modal-title">{{ title }}</h6>
      <CButtonClose @click="mtd_close" class="text-white" />
    </template>
    <template #footer>
      <CButton @click="mtd_close" color="danger">Cancelar</CButton>
      <CButton @click="mtd_commit" color="info" :disabled="cp_button_commit"
        >Aceptar</CButton
      >
    </template>
  </CModal>
</template>

<script>
import { bus } from "../../../main";
export default {
  name: "c-modal-delete",
  data() {
    return {
      quantity: "",
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    item: {},
    size: String,
  },
  computed: {
    cp_button_commit: function () {
        if (parseFloat(this.quantity) > 0) return false;
        return true;
    },
  },
  created() {
    this.quantity="";
  },
  methods: {
    mtd_close() {
      this.$emit("close_add");
    },
    mtd_commit() {
      this.$emit("mtd_commit_add",this.quantity);
      this.quantity="";
    },    
    /** helpers */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>