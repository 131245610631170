<template>
  <div>
    <CRow v-if="user.roles == 2">
      <!-- Lista de Resultdos-->
      <CCol lg="12">
        <div class="col-md-12 animate__animated animate__bounceInUp container">
          <div class="row">
            <div class="col-md-12 d-flex justify-content-around flex-wrap">
              <div>
                <span
                  class="badge badge-success px-3 py-2 m-2"
                  style="font-size: 18px"
                  >TOTAL: S/ {{ parseFloat(credit.after).toFixed(2) }}</span
                >
              </div>
              <div>
                <span
                  class="badge badge-info px-3 py-2 m-2"
                  style="font-size: 18px"
                  >TOTAL PAGADO: S/.
                  {{ parseFloat(credit.amount).toFixed(2) }}</span
                >
              </div>
              <div>
                <span
                  class="badge badge-warning px-3 py-2 m-2"
                  style="font-size: 18px"
                  >SALDO: S/.
                  {{ parseFloat(credit.before).toFixed(2) }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </CCol>
      <!-- <CCol xxl="6" lg="6" md="6" sm="12" xs="12" class="mt-2">
        <CCard>
          <CCardHeader>
            <strong> DETALLE</strong>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol lg="12" md="12" sm="12" xs="12">
                <div class="position-relative table-responsive">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Fecha</th>
                        <th>Producto</th>
                        <th>Cantidad</th>
                        <th>Precio</th>
                        <th>Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in credit.detail" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>
                          {{
                            item.created_at.substring(8, 10) +
                            "/" +
                            item.created_at.substring(5, 7) +
                            "/" +
                            item.created_at.substring(0, 4) +
                            " " +
                            item.created_at.substring(11, 16)
                          }}
                        </td>
                        <td>{{ item.product.name }}</td>
                        <td>{{ item.quantity }}</td>
                        <td>{{ item.price }}</td>
                        <td>
                          {{
                            parseFloat(item.quantity * item.price).toFixed(2)
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol xxl="6" lg="6" md="6" sm="12" xs="12" class="mt-2">
        <CCard>
          <CCardHeader>
            <strong> PAGOS</strong>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol lg="12" md="12" sm="12" xs="12">
                <div class="position-relative table-responsive">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Fecha</th>
                        <th>Monto</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in credit.charge" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>
                          {{
                            item.date.substring(8, 10) +
                            "/" +
                            item.date.substring(5, 7) +
                            "/" +
                            item.date.substring(0, 4) 
                          }}
                        </td>
                        <td>{{ item.amount }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol> -->
      <CCol xxl="12" lg="12" md="12" sm="12" xs="12" class="mt-2">
        <CCard>
          <CCardHeader>
            <strong> DETALLE</strong>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol lg="12" md="12" sm="12" xs="12">
                <div class="position-relative table-responsive">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Fecha</th>
                        <th>Decripción</th>
                        <th>Monto</th>
                        <th>Saldo</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in det" :key="index" :class="item.type == 1?'table-danger':'table-success' ">
                        <td>{{index + 1}}</td>
                        <td>{{item.date}}</td>
                        <td>{{item.description}} </td>
                        <td>{{item.amount}} </td>
                        <td>{{item.saldo}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../main";

import cModalAddCarrito from "../components/shared/client/cModalAddCarrito.vue";
import CModalView from "../components/shared/client/cModalView.vue";

export default {
  name: "Dashboard",
  components: { cModalAddCarrito, CModalView },
  data() {
    return {
      prefix: "dashboard",
      user: null,
      credit: [],
      det:[]
    };
  },
  computed: {},
  created() {
    this.user = this.$store.getters.get__user;
    this.mtd_getData();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getData: function () {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.credit = response.credit;
          this.det = response.det;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
  },
};
</script>
